import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/ContactLifeCycle/ContactLifeCycleDetailsForm/types'
import * as Styled from 'views/pages/ContactLifeCycle/ContactLifeCycleDetailsForm/styled'
import { Input, TextArea, FormComponents } from '@agro-club/frontend-shared'
import LifeCycleStageSelect from 'views/components/LifeCycleStageSelect/LifeCycleStageSelect'
import LifeCycleChangeReasonSelect from 'views/components/LifeCycleChangeReasonSelect/LifeCycleChangeReasonSelect'

type PropsType = {
  inOrderCreationMode?: boolean
  mode: 'edit' | 'create'
  userId?: string
}

const ContactLifeCycleForm: React.FC<PropsType> = ({}: PropsType) => {
  const { t } = useTranslation(['contactLifeCycle', 'common', 'validation', 'contactLifeCycleOrder'])
  const formik = useFormikContext<FormFields>()

  return (
    <>
      <FormComponents.FormSection title={t('form.personaData')}>
        <Styled.ContactLifeCyclePersonalDataWrapper>
          <Styled.NameArea>
            <Input label={t('common:firstName')} {...formik.getFieldProps('first_name')} disabled={true} />
          </Styled.NameArea>
          <Styled.LastNameArea>
            <Input label={t('common:lastName')} {...formik.getFieldProps('last_name')} disabled={true} />
          </Styled.LastNameArea>
          <Styled.PhoneNumberArea>
            <Input name={'phone'} label={t('form.phone')} disabled={true} />
          </Styled.PhoneNumberArea>
          <Styled.AdditionalPhoneNumberArea>
            <Input name={'additional_phone'} label={t('common:additionalPhone')} disabled={true} />
          </Styled.AdditionalPhoneNumberArea>
          <Styled.EmailArea>
            <Input label={t('common:email')} {...formik.getFieldProps('email')} disabled={true} />
            {formik.values.email_consent && <Styled.EmailConsent>{t('form.emailConsent')}</Styled.EmailConsent>}
            {!formik.values.email_consent && <Styled.NoEmailConsent>{t('form.noConsent')}</Styled.NoEmailConsent>}
          </Styled.EmailArea>
          <Styled.TerritoryArea>
            <Input label={t('form.territory')} {...formik.getFieldProps('territory')} disabled={true} />
          </Styled.TerritoryArea>
          <Styled.CityArea>
            <Input label={t('form.city')} {...formik.getFieldProps('city')} disabled={true} />
          </Styled.CityArea>
          <Styled.ProvinceArea>
            <Input label={t('form.province')} {...formik.getFieldProps('province')} disabled={true} />
          </Styled.ProvinceArea>
        </Styled.ContactLifeCyclePersonalDataWrapper>
      </FormComponents.FormSection>
      <FormComponents.FormSection title={t('form.lifeCycleData')}>
        <Styled.LifeCycleDataWrapper>
          <Styled.LifeCycleArea>
            <LifeCycleStageSelect
              label={t('form.lifeCycleStage')}
              {...formik.getFieldProps('life_cycle_stage')}
              onChange={e => formik.setFieldValue('life_cycle_stage', e ?? null)}
              placeholder={t('form.lifeCycleStageSelect')}
              invalid={formik.touched.life_cycle_stage && !!formik.errors.life_cycle_stage}
              errorText={formik.errors.life_cycle_stage}
              required
            />
          </Styled.LifeCycleArea>
          <Styled.ReasonArea>
            <LifeCycleChangeReasonSelect
              label={t('form.reasonForChange')}
              {...formik.getFieldProps('reason_for_change')}
              onChange={e => formik.setFieldValue('reason_for_change', e ?? null)}
              placeholder={t('form.lifeCycleChangeReasonSelect')}
              invalid={formik.touched.reason_for_change && !!formik.errors.reason_for_change}
              errorText={formik.errors.reason_for_change}
              required
            />
          </Styled.ReasonArea>
        </Styled.LifeCycleDataWrapper>
        <Styled.LifeCycleCommentWrapper>
          <Styled.CommentArea>
            <TextArea
              label={t('form.comment')}
              {...formik.getFieldProps('comment')}
              invalid={formik.touched.comment && !!formik.errors.comment}
              errorText={formik.errors.comment}
              required
            />
          </Styled.CommentArea>
        </Styled.LifeCycleCommentWrapper>
      </FormComponents.FormSection>
    </>
  )
}

export default ContactLifeCycleForm
