import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { FormikContext, useFormik } from 'formik'
import { Button, SectionBody, SectionContainer } from '@agro-club/frontend-shared'
import { NoteType } from 'types/entities'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import { FormFields } from 'views/pages/Note/NoteDetailsForm/types'
import NoteForm from 'views/pages/Note/NoteDetailsForm/NoteForm'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import styled, { StyledProps } from 'styled-components'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import NoteSelectors from 'modules/domain/note/selectors'
import { noop } from 'helpers/noop'

export type FormData = FormFields

export const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  margin-bottom: 16px;
`

export const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: start;
`

type Props = {
  mode: 'edit' | 'create'
  onSubmit(values: FormData): void
  progress?: Progress
  onCancel(): void
  onRemove?(): void
  initialValues?: FormData
}

const NoteDetailsForm: React.FC<Props> = ({
  mode,
  onSubmit,
  progress,
  onCancel,
  onRemove,
  initialValues: {
    parent_id = '',
    note_type = NoteType.ContactNote,
    note_title = '',
    note_text = '',
    created_by = '',
  } = {},
}) => {
  const { t } = useTranslation(['notes', 'common', 'validation'])
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)
  const removeProgress = useSelector(NoteSelectors.removeProgress)

  const schema = useMemo(() => {
    return Yup.object().shape({
      parent_id: Yup.string().required(t('validation:field_required')),
      note_type: Yup.mixed().oneOf([NoteType.ContactNote, NoteType.CompanyNote, NoteType.FarmerNote]),
      note_text: Yup.string().required(t('validation:field_required')),
    })
  }, [t, phoneCodes])

  const handleSubmit = () => {
    if (!formik.isValid) {
      return
    }
    const { ...common } = formik.values

    const result: FormData = {
      ...common,
    }

    onSubmit(result)
  }

  const formik = useFormik<FormFields>({
    initialValues: {
      parent_id,
      note_type,
      note_title,
      note_text,
      created_by,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <FormikContext.Provider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Wrapper>
            <Column>
              <SectionContainer>
                <SectionBody>
                  <NoteForm mode={mode} />
                </SectionBody>
              </SectionContainer>
            </Column>
          </Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            name={'submit-button'}
            filled={true}
            progress={progress}
            disabled={!formik.dirty || progress === Progress.WORK}
            intent={'primary'}
            onClick={formik.submitForm}
          >
            {t('common:save')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
          {isAdmin && mode === 'edit' && (
            <StickyFooterDeleteBtn
              onRemove={onRemove || noop}
              removeProgress={removeProgress}
              popoverText={t('form.removeNote')}
            />
          )}
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikContext.Provider>
  )
}

export default NoteDetailsForm
