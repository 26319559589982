import styled from 'styled-components'
import { Button, IconBin, Checkbox as CheckboxComponent } from '@agro-club/frontend-shared'

export const ContactLifeCyclePersonalDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'firstname lastname'
    'phone additionalphone'
    'email territory'
    'city province';
`

export const NameArea = styled.div`
  grid-area: firstname;
`
export const LastNameArea = styled.div`
  grid-area: lastname;
`
export const PhoneNumberArea = styled.div`
  grid-area: phone;
`
export const AdditionalPhoneNumberArea = styled.div`
  grid-area: additionalphone;
`
export const EmailArea = styled.div`
  grid-area: email;
`
export const TerritoryArea = styled.div`
  grid-area: territory;
`
export const CityArea = styled.div`
  grid-area: city;
`
export const ProvinceArea = styled.div`
  grid-area: province;
`

export const EmailConsent = styled.div`
  font-size: 10px;
  text-align: right;
  color: green;
`
export const NoEmailConsent = styled.div`
  font-size: 10px;
  text-align: right;
  color: red;
`

export const LifeCycleDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'lifecycle reason';
`
export const LifeCycleArea = styled.div`
  grid-area: lifecycle;
`
export const ReasonArea = styled.div`
  grid-area: reason;
`

export const LifeCycleCommentWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 2fr;
  grid-template-areas: 'comment';
`
export const CommentArea = styled.div`
  grid-area: comment;
`
export const PermissionsWrapper = styled.div`
  width: 200px;
`

export const RemoveButton = styled(Button)`
  margin-left: auto;
`

export const RemoveBtn = styled(IconBin)`
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 12px;
  transition: 0.3s fill;

  &:hover {
    fill: ${({ theme }) => theme.color.primary500};
  }
`

export const Checkbox = styled(CheckboxComponent)`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary300};
    cursor: pointer;
  }
`

export const EmptyList = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const CommentBlock = styled.div`
  width: 480px;
`

export const EmailSwitchArea = styled.div`
  margin-top: 8px;
`
