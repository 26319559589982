import { WizardData } from '@agro-club/frontend-shared'
import { Company } from 'modules/domain/company/types'
import { Product } from 'modules/domain/product/types'
import { StorefrontSku } from 'modules/domain/storefront/types'
import { StorefrontCard } from 'modules/domain/storefrontCard/types'

export enum Status {
  Deleted = 'deleted',
  Active = 'active',
  Inactive = 'inactive',
}

export enum CompanyType {
  Agro = 'agro',
  Producer = 'producer',
  Distributor = 'distributor',
  User = 'user',
}

export enum Currency {
  CAD = 'CAD',
  EUR = 'EUR',
  RUB = 'RUB',
  USD = 'USD',
}

export enum Permissions {
  MANAGER = 'manager',
  ADMIN = 'admin',
  HEAD = 'head',
}

export enum ROLES {
  USER = 'user',
  FARMER = 'farmer', // deprecated
  AGRO_ADMIN = 'agro:admin',
  AGRO_MANAGER = 'agro:manager',
  PRODUCER_HEAD = 'producer:head',
  PRODUCER_MANAGER = 'producer:manager',
  DISTRIBUTOR_HEAD = 'distributor:head',
  DISTRIBUTOR_MANAGER = 'distributor:manager',
}

export enum FEATURE_FLAGS_ROLES {
  ALL = 'all',
  USER = 'user',
  AGRO_ADMIN = 'agro:admin',
  AGRO_MANAGER = 'agro:manager',
  PRODUCER_HEAD = 'producer:head',
  PRODUCER_MANAGER = 'producer:manager',
  DISTRIBUTOR_HEAD = 'distributor:head',
  DISTRIBUTOR_MANAGER = 'distributor:manager',
}

export function isAgro(role?: ROLES | null) {
  return !!role && [ROLES.AGRO_ADMIN, ROLES.AGRO_MANAGER].includes(role)
}
export function isAdmin(role?: ROLES | null) {
  return role === ROLES.AGRO_ADMIN
}
export function isProducer(role?: ROLES | null) {
  return !!role && [ROLES.PRODUCER_HEAD, ROLES.PRODUCER_MANAGER].includes(role)
}
export function isProducersHead(role?: ROLES | null) {
  return role === ROLES.PRODUCER_HEAD
}
export function isProducersManager(role?: ROLES | null) {
  return role === ROLES.PRODUCER_MANAGER
}
export function isDistributorHead(role?: ROLES | null) {
  return role === ROLES.DISTRIBUTOR_HEAD
}
export function isDistributor(role?: ROLES | null) {
  return !!role && [ROLES.DISTRIBUTOR_HEAD, ROLES.DISTRIBUTOR_MANAGER].includes(role)
}
export function isDistributorManager(role?: ROLES | null) {
  return role === ROLES.DISTRIBUTOR_MANAGER
}
export function isUsersCompanyProducer(company?: Company | null) {
  return !!company && company.company_type === CompanyType.Producer
}
export function isUsersCompanyDistributor(company?: Company | null) {
  return !!company && company.company_type === CompanyType.Distributor
}
export function isUsersCompanyHeadDistributor(company?: Company | null) {
  return isUsersCompanyDistributor(company) && company?.has_branch_company
}
export function isUsersCompanyBranchDistributor(company?: Company | null) {
  return isUsersCompanyDistributor(company) && !!company?.head_company_relation
}

export function isUsersCompanyBasicDistributor(company?: Company | null) {
  return isUsersCompanyDistributor(company) && !company?.head_company_relation && !company?.has_branch_company
}

export type LocalizedValue<T = string> = {
  en?: T
  ru?: T
  fr?: T
  es?: T
  en_US?: T
  es_SV?: T
}

export type AvailableLanguages = keyof LocalizedValue
export const availableLanguagesList: Array<AvailableLanguages> = ['en', 'fr']

export enum DiscountTypes {
  Quantity = 'quantity',
  Promocode = 'promocode',
  Gift = 'non_monetary',
  Percentage = 'percentage',
  HistoryQuantity = 'history_quantity',
}

export type ProductEntry = {
  product_id: string
  quantity: number
  price?: string
  cost?: string
  total?: string
  packaging?: number
  product: Product
  seed_treatment_id?: string
  options?: string[]
  wizard_comment?: string
  wizard_data?: WizardData<any>
}

export type SkuProductEntry = {
  id?: string
  sku_id?: string
  quantity: string
  final_qty?: string
  delivered_qty?: string
  shipped_qty?: string
  standard_qty?: string
  standard_final_qty?: string
  standard_delivered_qty?: string
  discount?: number
  price_fcc?: string
  total_fcc?: string
  credit_facility?: string
  wizard_comment?: string
  wizard_data?: WizardData<any>
  comment?: string

  sku?: StorefrontSku
  card_id?: string
  sku_card?: StorefrontCard
}

export enum Sections {
  FarmerOrders = 'farmerOrders',
  Orders = 'orders',
  FCCReport = 'fccReport',
  RetailerOrders = 'retailerOrders',
  RetailerSkuOrders = 'retailerSkuOrders',
  ReturnDeclarations = 'returnDeclarations',
  Targets2 = 'new-targets',
  Checkout = 'checkout',
  InventorySku = 'inventorySku',
  InventoryExchange = 'inventoryExchange',
  InventoryRequests = 'inventoryRequests',
  FarmerPlans = 'farmerPlans',
  MarketingCampaigns = 'marketingCampaigns',
  SalesTeamPlans = 'salesTeamPlans',
  Storefront = 'storefront',
  Allocation = 'allocation',
  ProductsAvailability = 'productsAvailability',
  ProductSettings = 'productSettings',
  ChangeLog = 'change-log',
  Reports = 'reports',
  Reconciliations = 'reconciliations',
}

export enum Buttons {
  Summary = 'summary',
  DownloadXlsx = 'downloadXlsx',
  Completed = 'completed',
  Uncancel = 'uncancel',
}

export enum Actions {
  Create = 'create',
  Cancel = 'cancel',
  Update = 'update',
}

export enum Tables {
  FarmerOrders = 'farmerOrders',
  DistributorOrders = 'distributorOrders',
  ReturnDeclarations = 'returnDeclarations',
}

export enum Columns {
  SeedTreatment = 'seedTreatment',
}

export enum Filters {
  SeedTreatment = 'seedTreatment',
  Household = 'household',
}

export enum FeatureFlagTypes {
  Field = 'field',
  Enum = 'enum',
  CrmSection = 'crmSection',
  Action = 'action',
  Custom = 'custom',
  Button = 'button',
  TableColumn = 'tableColumn',
  Filter = 'filter',
}

export enum FeatureFlagModifiers {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Hidden = 'hidden',
  Required = 'required',
  Restricted = 'restricted',
  DemoMode = 'DemoMode',
  NotRequired = 'notRequired',
}

export enum FieldNames {
  CheckPayeeName = 'checkPayeeName',
  Partnership = 'partnership',
  TaxID = 'taxId',
  FarmName = 'farmName',
  Gdpr = 'gdpr',
  RetailerRegions = 'retailerRegionsIds',
  DeliveryAddress = 'deliveryAddress',
  ConfirmAccuracy = 'confirmAccuracy',
  FarmerEmail = 'farmerEmail',
  Units = 'units',
  Attachments = 'attachments',
}

export enum FieldLocation {
  AccountForm = 'accountForm',
  Checkout = 'checkout',
  CustomerModal = 'customerModal',
  FarmerOrders = 'farmerOrders',
  OrderList = 'orderList',
  SkuOrders = 'skuOrders',
}

export enum CustomFeatureName {
  FilterRetailersByProvince = 'filterRetailersByProvince',
  FarmerConfirmRequired = 'farmerConfirmRequired',
  DisplayCategoryFilter = 'displayCategoryFilter',
  TrustRetailer = 'trustRetailer',
  RetailerInteractionByProducer = 'retailerInteractionByProducer',
  AvailabilityDotsVisible = 'availabilityDotsVisible',
  AutoRefreshBySchedule = 'autoRefreshBySchedule',
  OutOfStockQtyEditingForbidden = 'outOfStockQtyEditingForbidden',
  ShowShippedQty = 'showShippedQty',
  CommentsOfRejectReasonsVisible = 'commentsOfRejectReasonsVisible',
  WithoutTime = 'withoutTime',
}

export type FeatureFlagPermission = {
  [permission: string]: boolean
}

export type FeatureFlags = {
  [key: string]: FeatureFlagPermission
}

export type UserProfileFeatureFlags = {
  [key: string]: boolean
}

export enum PriceType {
  Msrp = 'msrp',
  None = 'not_specified',
}

export enum Operation {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

/** Function will generate feture flugs object.
 * @param flagSettings name of flug and roles it should be true for
 * */
export const generateFetureFlagsObject = (
  flagSettings: { flagName: string; rolesEnabledFor: FEATURE_FLAGS_ROLES[] }[],
) => {
  return flagSettings.reduce(
    (flags, flagSettings) => ({
      ...flags,
      [flagSettings.flagName]: flagSettings.rolesEnabledFor.reduce((roles, role) => ({ ...roles, [role]: true }), {}),
    }),
    {},
  )
}

export enum ContactType {
  Person = 'person',
}

export enum NoteType {
  ContactNote = 'contact_note',
  CompanyNote = 'company_note',
  FarmerNote = 'farmer_note',
}

export enum LifeCycleStage {
  Lead = 'Lead',
  Prospect = 'Prospect',
  Customer = 'Customer',
  Lapsed = 'Lapsed',
  NotAFarmer = 'Not a Farmer',
}

export enum LifeCycleTerritory {
  Border = 'Border',
  CentralAlberta = 'Central Alberta',
  EastCentralSask = 'East Central Sask',
  EasternManitoba = 'Eastern Manitoba',
  HighlineGrainGrowers = 'Highline Grain Growers',
  Meridian = 'Meridian',
  NorthCentralAlberta = 'North Central Alberta',
  NorthEasternSask = 'North Eastern Sask',
  PeaceEast = 'Peace East',
  PeaceWest = 'Peace West',
  SouthernAlberta = 'Southern Alberta',
  SouthernSask = 'Southern Sask',
  ThunderSeed = 'Thunder Seed',
  UFAPHHO = 'UFA - P&H HO',
  Unassigned = 'Unassigned',
  WesternManitoba = 'Western Manitoba',
  WesternSask = 'Western Sask',
}

export enum LifeCycleChangeReason {
  NotCurrentlyGrowingCanola = 'Not Currently Growing Canola',
  Retired = 'Retired',
  Deceased = 'Deceased',
  GrowingCanola = 'Growing Canola',
  ThinkingAboutGrowingCanola = 'Thinking about Growing Canola',
  NotAFarmer = 'Not a Farmer',
}
